import { timeInSeconds } from '../constants';

const checkRequestErrors = (cache: any, time: number) => {
  const errors: any = [];
  const cacheKeys = Object.keys(cache);

  cacheKeys.forEach((e) => {
    if (cache[e]?.error && cache[e]?.error?.graphQLErrors?.[0]?.extensions?.code !== 404) {
      errors?.push(e);
    }
  });

  const urqlState = cache;
  let revalidate = time;

  if (errors?.length) {
    revalidate = timeInSeconds.ONE_SEC;
    errors?.forEach((e: any) => delete urqlState[e]);
  }

  return {
    revalidate,
    urqlState,
  };
};

export default checkRequestErrors;
