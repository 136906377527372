const metaData = {
  main: {
    title: 'Review management software | LocalProf',
    description:
      'Streamline your business with our tools: cross-platform profile management, centralized reviews, customizable customer surveys, and a web landing page creator',
    keywords:
      'business profile manager, gbp manager, business platforms tool, business profile improvement, online reputation manager, Local ceo, Review management platform, Online reputation',
  },
  pricing: {
    title: 'Affordable Pricing Plans for LocalProf.com Services',
    description:
      'Discover pricing plans for our services, tailored to meet your needs. Explore our competitive rates and get the best value for professional solutions today',
  },
  products: {
    locals: {
      title: 'Unified Business Profile Management and Analysis Tool',
      description:
        'Manage your business profiles across multiple platforms with analytics tools. Improve visibility and performance with our reports and enhance your profiles',
      keywords:
        'business profile manager, gbp manager, business profile improvement, online reputation manager, Local ceo, Online reputation',
    },
    reviews: {
      title: 'Comprehensive Review Management and Improvement Platform',
      description:
        'Centralize your reviews, analyze statistics, send follow-up requests, and enhance ratings universally. Elevate your online reputation and drive business growth',
      keywords:
        'business reviews manager, business profile improvement, online reputation manager, Local ceo, Review management platform, Online reputation',
    },
    pages: {
      title: 'Customizable Web Landing Page Creator for Your Business',
      description:
        'Enhance your online presence and engage your audience with a dynamic and personalized landing page. Design and customize your own web landing page effortlessly',
      keywords:
        'landing page creator, site for business, fast site development, business profile improvement, online reputation manager, Local ceo, Review management platform, Online reputation',
    },
  },
  terms: {
    title: 'Terms and Conditions LocalProf.com',
    description: 'Public Offer (Agreement) for the provision of services LocalProf.com ‼',
  },
  privacyPolicy: {
    title: 'Privacy Policy LocalProf.com',
    description: 'Privacy Policy LocalProf.com ‼',
  },
  cookie: {
    title: 'Cookie Policy LocalProf.com',
    description: 'Cookie Policy LocalProf.com ‼',
  },
};

export default metaData;
