/* eslint-disable import/prefer-default-export */
const INVALID_FIELD = 'INVALID_FIELD';
const INVALID_PASSWORD = 'INVALID_PASSWORD';
const INVALID_LOGIN = 'INVALID_LOGIN';
const AUTHORIZATION_LIMIT = 'AUTHORIZATION_LIMIT';
const UNCOMPLETED_TASKS = 'UNCOMPLETED_TASKS';
const TITLE = 'title';
const DESCRIPTION = 'description';
const STREET = 'street';
const COST = 'cost';
const CONTACT = 'contact';
const DUPLICATED_PROPOSUAL = 'DUPLICATED_PROPOSUAL';
const DUPLICATED_TENDER = 'DUPLICATED_TENDER';
const UNACCEPTABLE = 'UNACCEPTABLE';
const SUBSCRIPTION_EXISTS = 'SUBSCRIPTION_EXISTS';
const INVALID_EMAIL = 'INVALID_EMAIL';
const EMAIL_BUSY = 'EMAIL_BUSY';
const SUBDOMAIN_BUSY = 'SUBDOMAIN_BUSY';

export const errorsList = [
  11000,
  INVALID_FIELD,
  404,
  402,
  INVALID_LOGIN,
  AUTHORIZATION_LIMIT,
  INVALID_PASSWORD,
  TITLE,
  DESCRIPTION,
  STREET,
  COST,
  CONTACT,
  UNCOMPLETED_TASKS,
  DUPLICATED_PROPOSUAL,
  DUPLICATED_TENDER,
  UNACCEPTABLE,
  SUBSCRIPTION_EXISTS,
  INVALID_EMAIL,
  EMAIL_BUSY,
  SUBDOMAIN_BUSY,
];

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
