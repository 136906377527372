import { ChakraProvider } from '@chakra-ui/react';
import { initializeGtm } from '@repo/shared/shared';
import type { AppProps } from 'next/app';
import { Viewport } from 'next/dist/lib/metadata/types/extra-types';
import { theme } from '@/app/theme';
import { Footer } from '@/widgets/Footer';
import { GQLProvider } from '@/widgets/GQLProvider';
import { Header } from '@/widgets/header';
import { config } from '@/shared/constants';
import { Device } from '@/shared/enums';
import { parseSSGParams } from '@/shared/helpers';
import { useSaveLeadParams } from '@/shared/hooks';
import { GlobalStateProvider } from '@/shared/providers';

export const viewport: Viewport = {
  initialScale: 1,
  width: 'device-width',
};

initializeGtm(config.gtmId);

export default function MyApp({ Component, pageProps, router }: AppProps) {
  const { device, isAds } = parseSSGParams(router.query);
  const isMobile = device === Device.MOBILE;

  useSaveLeadParams(isAds);

  return (
    <ChakraProvider theme={theme}>
      <GlobalStateProvider isMobileInitValue={isMobile}>
        <GQLProvider>
          <Header />
          <Component {...pageProps} />
          <Footer />
        </GQLProvider>
      </GlobalStateProvider>
    </ChakraProvider>
  );
}
