import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const LoginIcon = (props: IconProps) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='auto'
    {...props}
  >
    <path
      stroke='none'
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M9 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2' />
    <path d='M3 12h13l-3 -3' />
    <path d='M13 15l3 -3' />
  </Icon>
);

export default LoginIcon;
