import Head from 'next/head';
import { IFAQ } from '@/widgets/FAQ';
import { brand, config } from '../constants';

interface IListItems {
  heading: string;
  list: { heading: string; description: string }[];
}

interface JsonLdDecoratorProps {
  faqList?: IFAQ[];
  listItems?: IListItems[];
  tariffs?: { price: number; name: string }[];
}

const JsonLdDecorator = ({ faqList, listItems, tariffs }: JsonLdDecoratorProps) => {
  return (
    <Head>
      {/* FAQ */}
      {faqList && (
        <script
          id='JsonLdFAQ'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: faqList.map(({ answer, question }) => ({
                '@type': 'Question',
                name: question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: answer,
                },
              })),
            }),
          }}
        />
      )}

      {/* ListItems */}
      {listItems &&
        listItems.map(({ heading, list }, index) => (
          <script
            key={index}
            id={`JsonLdListItem-${index}`}
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                name: heading,
                itemListElement: list.map(({ heading, description }, index) => ({
                  '@type': 'ListItem',
                  position: index + 1,
                  item: {
                    '@type': 'Thing',
                    name: heading,
                    description: description,
                  },
                })),
              }),
            }}
          />
        ))}

      {/* Subscriptions */}
      {tariffs && (
        <script
          id='JsonLdPricing'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Product',
              name: 'Subscription Plans',
              description: 'Simple, transparent pricing for every business',
              image: `${config.baseUrl}/main-page/subscription-card.png`,
              offers: tariffs.map(({ price, name }) => ({
                '@type': 'Offer',
                name,
                price,
                priceCurrency: 'USD',
                url: `${config.baseUrl}/pricing`,
                availability: 'https://schema.org/InStock',
                itemCondition: 'https://schema.org/NewCondition',
                seller: {
                  '@type': 'Organization',
                  name: brand,
                },
              })),
            }),
          }}
        />
      )}
    </Head>
  );
};

export default JsonLdDecorator;
