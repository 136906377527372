import { Link } from '@chakra-ui/next-js';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { brand, email, navLinks, privacyPages } from '@/shared/constants';
import { DefaultContainer, LocalprofLogo } from '@/shared/ui';
import { localStorage } from '@/shared/utils';
import SocialMediaLinks from './SocialMediaLinks';

const linkBoxStyles = {
  alignItems: 'center',
  gap: 'sm',
  justifyContent: 'center',
};

const Footer = () => {
  const isShowProducts = localStorage.getItem('isShowProducts') !== 'false';
  let currentLinks = navLinks;

  if (!isShowProducts) {
    currentLinks = navLinks.filter((item) => item.title !== 'Products');
  }

  return (
    <DefaultContainer
      alignItems='center'
      bg='bg.dark'
      containerProps={{
        as: 'footer',
      }}
      flexDir={{ base: 'column-reverse', md: 'row' }}
      gap='md'
      justifyContent='space-between'
    >
      <Flex
        alignItems='center'
        flexDir='column'
      >
        <Flex
          _hover={{
            transform: 'scale(1.01)',
          }}
          alignItems='center'
          as={Link}
          gap='sm'
          href='/'
        >
          <LocalprofLogo
            aria-label='Home'
            boxSize={8}
            color='icon.light'
          />

          <Text
            color='text.light'
            fontSize='xl'
            fontWeight='semibold'
          >
            {brand}
          </Text>
        </Flex>

        <Flex
          alignItems='center'
          flexDir={{ base: 'row', md: 'column' }}
          mt='sm'
        >
          <Text color='text.light'>
            {new Date().getFullYear()} {brand} LLC.
          </Text>
          <Text
            color='text.light'
            ml={{ base: 2, md: 0 }}
          >
            All rights reserved
          </Text>
        </Flex>
      </Flex>

      <Flex
        as='nav'
        flexDir='column'
        gap='sm'
      >
        <Flex
          as='ul'
          {...linkBoxStyles}
          flexWrap='wrap'
        >
          {currentLinks.map(({ title, items, href }) => {
            if (items) {
              return (
                <Fragment key={title}>
                  {items.map(({ name, href }) => (
                    <Box
                      key={name}
                      as='li'
                      listStyleType='none'
                    >
                      <Link
                        color='text.light'
                        href={href}
                      >
                        {name}
                      </Link>
                    </Box>
                  ))}
                </Fragment>
              );
            }
            return (
              <Box
                key={title}
                as='li'
                listStyleType='none'
              >
                <Link
                  color='text.light'
                  href={href!}
                >
                  {title}
                </Link>
              </Box>
            );
          })}
        </Flex>
        <Flex
          as='ul'
          {...linkBoxStyles}
          flexWrap='wrap'
        >
          {privacyPages.map(({ name, url }) => (
            <Box
              key={name}
              as='li'
              listStyleType='none'
            >
              <Link
                color='text.light'
                href={`/${url}`}
              >
                {name}
              </Link>
            </Box>
          ))}
        </Flex>
      </Flex>

      <Flex
        flexDir='column'
        gap='sm'
      >
        <Link
          color='text.light'
          fontSize='lg'
          fontWeight='semibold'
          href={`mailto:${email}`}
          textAlign='center'
        >
          {email}
        </Link>
        <SocialMediaLinks />
      </Flex>
    </DefaultContainer>
  );
};

export default Footer;
