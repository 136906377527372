import { Button, type ButtonProps } from '@chakra-ui/react';
import { leadDataParamsKeys, pushToDataLayer } from '@repo/shared/shared';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { config } from '@/shared/constants';
import { useGlobalState } from '@/shared/providers';
import { localStorage } from '@/shared/utils';

interface IRedirectToAppButtonProps extends ButtonProps {
  pageName: string;
  buttonText: string;
  dataLayer?: {
    event?: string;
    data?: {};
  };
}

const RedirectToAppButton: FC<IRedirectToAppButtonProps> = ({
  pageName,
  buttonText,
  dataLayer,
  ...props
}) => {
  const router = useRouter();
  const { quizResult } = useGlobalState();

  const event = dataLayer?.event || `redirected_to_${pageName}`;

  const redirectToPage = () => {
    const params: Record<string, string> = {};

    Object.entries(leadDataParamsKeys).forEach(([param, _v]) => {
      const paramValue = localStorage.getItem(param);
      if (paramValue) params[param] = paramValue;
    });

    const query = new URLSearchParams(params);

    if (quizResult) {
      query.set('quiz', quizResult);
    }

    const url = `${config.reviewsAppUrl}/${pageName}${query.size > 0 ? `?${query.toString()}` : ''}`;
    pushToDataLayer(event, dataLayer?.data);
    router.push(url);
  };

  return (
    <Button
      size='sm'
      onClick={redirectToPage}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

export default RedirectToAppButton;
