import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const LocationsIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M10.828 9.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z' />
    <path d='M8 7l0 .01' />
    <path d='M18.828 17.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z' />
    <path d='M16 15l0 .01' />
  </Icon>
);

export default LocationsIcon;
