import { Link } from '@chakra-ui/next-js';
import { Flex, Text } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { RedirectToAppButton } from '@/widgets/RedirectToAppButton';
import { brand, headerHeight } from '@/shared/constants';
import { usePageName } from '@/shared/hooks';
import { DefaultContainer, LocalprofLogo } from '@/shared/ui';

const MobileMenu = dynamic(() => import('./MobileMenu'));
const DesktopMenu = dynamic(() => import('./DesktopMenu'));

const Header = () => {
  const pageName = usePageName();

  return (
    <DefaultContainer
      alignItems='center'
      containerProps={{
        as: 'header',
        position: 'fixed',
        bg: 'white',
        boxShadow: 'lg',
        zIndex: 'sticky',
        top: 0,
      }}
      flexDir='row'
      gap={{ base: 'md', lg: '4rem' }}
      h={headerHeight}
      justifyContent={{ base: 'space-between', lg: 'start' }}
      py='0'
    >
      <MobileMenu />
      <Flex
        _hover={{
          transform: 'scale(1.01)',
        }}
        alignItems='center'
        as={Link}
        gap='sm'
        href='/'
        ml={{ base: '2xl', lg: 0 }}
      >
        <LocalprofLogo
          aria-label='Home'
          boxSize={8}
        />
        <Text
          display={{ base: 'none', md: 'block' }}
          fontSize='xl'
          fontWeight='semibold'
        >
          {brand}
        </Text>
      </Flex>

      <Flex
        alignItems='center'
        justifyContent={{ base: 'end', lg: 'space-between' }}
        w={{ base: 'fit-content', lg: 'full' }}
      >
        <DesktopMenu />

        <Flex gap='md'>
          <RedirectToAppButton
            buttonText='Login'
            dataLayer={{ data: { redirected_from: `${pageName}_header` } }}
            display={{ base: 'none', md: 'flex' }}
            pageName='login'
            variant='link'
          />
          <RedirectToAppButton
            buttonText='Start free trial'
            dataLayer={{ data: { redirected_from: `${pageName}_header` } }}
            pageName='signup'
            variant={{ base: 'link', md: 'solid' }}
          />
        </Flex>
      </Flex>
    </DefaultContainer>
  );
};

export default Header;
