import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const AlertIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='auto'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M12 9v4' />
    <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
    <path d='M12 16h.01' />
  </Icon>
);

export default AlertIcon;
