import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const BurgerIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M4 6h16' />
    <path d='M7 12h13' />
    <path d='M10 18h10' />
  </Icon>
);

export default BurgerIcon;
