import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const FoodIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='auto'
    {...props}
  >
    <path
      stroke='none'
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M7 4v17m-3 -17v3a3 3 0 1 0 6 0v-3' />
    <path d='M17 8m-3 0a3 4 0 1 0 6 0a3 4 0 1 0 -6 0' />
    <path d='M17 12v9' />
  </Icon>
);

export default FoodIcon;
