import { useEffect } from 'react';
import { useMutation as useMutationUrql } from 'urql';
import type { AnyVariables, DocumentInput } from 'urql';
import { MutationResponse } from '@/shared/types';

interface MutationActions {
  onCompleted?: (data: object) => void;
  onError?: (err: any) => void;
}

const useMutation = (
  mutation: DocumentInput<any, AnyVariables>,
  actions: MutationActions = {},
): MutationResponse => {
  const { onCompleted, onError } = actions;
  const [{ fetching, data, error }, action] = useMutationUrql(mutation);

  useEffect(() => {
    if (fetching) return;
    if (data && typeof data === 'object' && !fetching && onCompleted) {
      const dataKeys = Object.keys(data);
      const completedData = data?.[dataKeys?.[0]];
      if (completedData && completedData !== null) onCompleted(data);
    }
    if (error && !fetching && onError) {
      onError(error);
    }
  }, [fetching, data, error]);

  return { action, isLoading: fetching, data, error };
};

export default useMutation;
