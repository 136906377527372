import { Box, Flex } from '@chakra-ui/react';
import type { ContainerProps } from '@chakra-ui/react';
import { FC } from 'react';

interface DefaultContainerProps extends ContainerProps {
  containerProps?: ContainerProps;
}

const DefaultContainer: FC<DefaultContainerProps> = ({
  children,
  containerProps,
  bg,
  ...other
}) => (
  <Box
    bg={bg}
    w='full'
    {...containerProps}
  >
    <Flex
      flexDirection='column'
      maxW='container.2xl'
      mx='auto'
      px={{ base: 6, md: 12 }}
      py={{ base: 10, md: 20 }}
      {...other}
    >
      {children}
    </Flex>
  </Box>
);

export default DefaultContainer;
