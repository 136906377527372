import type { ButtonProps } from '@chakra-ui/react';
import * as rest from '@chakra-ui/theme-tools';

const { mode } = rest;

const overlayStyles = { backdropFilter: 'blur(3px)' };

function base(props: ButtonProps) {
  const { colorScheme: c } = props;
  const borderColor = mode('border.pure', 'whiteAlpha.300')(props);

  return {
    cursor: 'pointer',
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
  };
}

export const components = {
  Card: {
    variants: {
      solid: {
        container: {
          bg: 'bg.light',
          border: 'none',
          borderRadius: 'lg',
        },
        body: {
          p: 'lg',
        },
      },
    },
  },
  Button: {
    variants: {
      iconButton: {
        bg: 'none',
        color: 'icon.dark',
        size: 'sm',
        minW: 8,
        h: 8,
      },
      primary: (props: ButtonProps) => ({
        ...base(props),
        bg: 'gradient.pure',
        border: 'none',
        color: 'white',
        _hover: {
          _disabled: {
            bg: 'gradient.dark',
          },
          bg: 'gradient.dark',
        },
      }),
    },
    defaultProps: {
      colorScheme: 'primary',
    },
  },
  Drawer: {
    baseStyle: {
      overlay: overlayStyles,
    },
  },
  Modal: {
    baseStyle: {
      overlay: overlayStyles,
    },
  },
  Heading: {
    defaultProps: {
      size: '2xl',
    },
  },
};
