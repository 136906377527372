const container = {
  sm: '40rem',
  md: '48rem',
  lg: '64rem',
  xl: '80rem',
  '2xl': '90rem',
  full: '100%',
};

export const sizes = {
  container,
};
