import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import NextImage, { StaticImageData } from 'next/image';
import { FC, useMemo } from 'react';
import { generateImageSizeMediaQueries } from '@/shared/helpers';
import { ResponsiveValue } from '@/shared/types';

export interface ImageProps extends BoxProps {
  src: string | StaticImageData;
  imgSizes?: ResponsiveValue | string;
  alt?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  priority?: boolean;
  isEager?: boolean;
  unoptimized?: boolean;
}

const Image: FC<ImageProps> = ({
  id,
  src,
  imgSizes,
  alt = '',
  objectFit = 'cover',
  priority,
  isEager = false,
  unoptimized = false,
  ...boxProps
}) => {
  const sizes = useMemo(() => (imgSizes ? generateImageSizeMediaQueries(imgSizes) : undefined), []);
  const hasPlaceholder = typeof src === 'object' && !!src.blurDataURL;

  return (
    <Box
      height='full'
      position='relative'
      {...boxProps}
    >
      <NextImage
        alt={alt}
        fill
        loading={isEager ? 'eager' : 'lazy'}
        placeholder={hasPlaceholder ? 'blur' : 'empty'}
        priority={priority}
        sizes={sizes}
        src={src}
        style={{ objectFit }}
        unoptimized={unoptimized}
      />
    </Box>
  );
};

export default Image;
