const isProd = process.env.VERCEL_ENV === 'production';

const reviewsAppUrl = process.env.NEXT_PUBLIC_REVIEWS_APP_URL || 'https://app.localprof.com';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || 'https://localprof.com';

const apiUrl = isProd
  ? process.env.NEXT_PUBLIC_API_URL
  : process.env.NEXT_PUBLIC_API_URL || 'https://devapi.localprof.com';

if (!apiUrl) {
  throw new Error('NEXT_PUBLIC_API_URL is not defined');
}

const config = {
  isProd,
  apiUrl,
  reviewsAppUrl,
  baseUrl,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
} as const;

export default config;
