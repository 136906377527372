import { useRouter } from 'next/router';

const usePageName = () => {
  const router = useRouter();
  const pagesSegments = router?.pathname
    .split('/')
    .filter((segment) => segment !== '' && segment !== '[ssgParams]');

  const currentPage = pagesSegments?.length > 0 ? pagesSegments.join('_') : 'main';

  return currentPage;
};

export default usePageName;
