import { Box, type ContainerProps, SlideFade } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';

export interface IStepComponentProps {
  currentStep: number;
  onNext: () => void;
  onPrev: () => void;
}

interface ISlider extends ContainerProps {
  steps: FC<IStepComponentProps>[];
}

const Slider: FC<ISlider> = ({ steps, ...rest }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState('left');

  const handleNext = useMemo(
    () => () => {
      if (currentStep < steps.length - 1) {
        setSlideIn(false);
        setSlideDirection('left');
        setTimeout(() => {
          const newStep = currentStep + 1;
          setCurrentStep(newStep);
          setSlideIn(true);
        }, 300);
      }
    },
    [currentStep, steps.length],
  );

  const handlePrev = useMemo(
    () => () => {
      if (currentStep > 0) {
        setSlideIn(false);
        setSlideDirection('right');
        setTimeout(() => {
          const newStep = currentStep - 1;
          setCurrentStep(newStep);
          setSlideIn(true);
        }, 300);
      }
    },
    [currentStep, steps.length],
  );
  const Component = steps[currentStep];

  return (
    <Box
      overflow='hidden'
      {...rest}
    >
      <SlideFade
        in={slideIn}
        offsetX={slideDirection === 'right' ? 50 : -50}
        offsetY={0}
        style={{ zIndex: 10 }}
      >
        <Component
          currentStep={currentStep}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      </SlideFade>
    </Box>
  );
};

export default Slider;
