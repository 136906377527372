import { createClient } from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';
import { cacheExchange, fetchExchange, ssrExchange } from 'urql';
import { config } from '../constants';
import { errorsList } from '../constants/error';

let urqlClient: any = null;

export const retryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: (error: any) => !errorsList?.includes(error?.graphQLErrors?.[0]?.extensions?.code),
};

function initUrqlClient(clientOptions: any, canEnableSuspense = false) {
  const isServer = typeof window === 'undefined';
  if (isServer || !urqlClient) {
    urqlClient = createClient({
      url: `${config.apiUrl}/graphql`,
      ...clientOptions,
      suspense: canEnableSuspense && (isServer || clientOptions?.suspense),
      maskTypename: true,
    });

    urqlClient.toJSON = () => null;
  }

  return urqlClient;
}

const getServerClient = () => {
  const ssrCache = ssrExchange({ isClient: false });
  const client = initUrqlClient({
    exchanges: [cacheExchange, retryExchange(retryExchangeOptions), ssrCache, fetchExchange],
  });

  return {
    client,
    ssrCache,
  };
};

export default getServerClient;
