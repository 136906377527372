import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const ArrowDown = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='0.5'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
      fill='currentColor'
    />
  </Icon>
);

export default ArrowDown;
