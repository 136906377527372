const storage: Record<string, string> = {};

const isBrowser = typeof window !== 'undefined' && window.localStorage;

const setItem = (key: string, value: string) => {
  if (isBrowser) {
    window.localStorage.setItem(key, value);
  } else {
    storage[key] = value;
  }
};

const removeItem = (key: string) => {
  if (isBrowser) {
    window.localStorage.removeItem(key);
  } else {
    delete storage[key];
  }
};

const getItem = (key: string) => {
  if (isBrowser) {
    return window.localStorage.getItem(key);
  } else {
    return storage[key];
  }
};

export default { setItem, removeItem, getItem };
