import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import { components } from './components';
import { sizes } from './sizes';
import space from './spaces';

const theme = extendTheme({
  colors,
  space,
  sizes,
  radii: {
    none: '0',
    sm: '0.25rem',
    base: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '2.5rem',
    full: '9999px',
  },
  components,
  styles: {
    global: {
      body: {
        color: 'text.pure',
      },
    },
  },
});

export default theme;
