import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const CircleCheckIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='auto'
    {...props}
  >
    <path
      stroke='none'
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
    <path d='M9 12l2 2l4 -4' />
  </Icon>
);

export default CircleCheckIcon;
