import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/react';

const UsersIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
    <path d='M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1' />
    <path d='M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
    <path d='M17 10h2a2 2 0 0 1 2 2v1' />
    <path d='M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
    <path d='M3 13v-1a2 2 0 0 1 2 -2h2' />
  </Icon>
);

export default UsersIcon;
