import { FC } from 'react';
import { REQUIRED_FIELD_MSG, WRONG_EMAIL_MSG, emailRegExp } from '@/shared/constants';
import InputField, { InputFieldProps } from './InputField';

type EmailFieldProps = Omit<InputFieldProps, 'name' | 'placeholder'> &
  Partial<Pick<InputFieldProps, 'name' | 'placeholder'>> & {
    isRequired?: boolean;
  };

const EmailField: FC<EmailFieldProps> = ({
  label = 'Email',
  name = 'email',
  placeholder = 'E-mail',
  isRequired,
  validationParameters,
  ...restProps
}) => (
  <InputField
    label={label}
    name={name}
    placeholder={placeholder}
    validationParameters={{
      required: isRequired ? REQUIRED_FIELD_MSG(label) : undefined,
      pattern: {
        value: emailRegExp,
        message: WRONG_EMAIL_MSG,
      },
      ...validationParameters,
    }}
    {...restProps}
  />
);

export default EmailField;
