const WEEK = 604800;
const HALF_AN_HOUR = 1800;
const HOUR = 3600;
const DAY = 86400;
const ONE_SEC = 1;

const timeInSeconds = {
  WEEK,
  HALF_AN_HOUR,
  HOUR,
  DAY,
  ONE_SEC,
};

export default timeInSeconds;
