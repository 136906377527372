import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import type { InputProps } from '@chakra-ui/react';
import { FormFieldMeta } from '@repo/shared/shared';
import { FC } from 'react';
import { useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '@/shared/constants';

export interface InputFieldProps extends InputProps {
  name: string;
  placeholder: string;
  regExp?: RegExp | undefined;
  validationParameters?: object;
  label?: string;
  helperText?: string;
}

const InputField: FC<InputFieldProps> = ({
  name,
  placeholder,
  value,
  isRequired,
  validationParameters,
  label,
  helperText,
  onBlur,
  onChange,
  ...style
}) => {
  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG(label),
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: InputProps['onChange'] = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: InputProps['onBlur'] = (event) => {
    onFieldBlur();
    onBlur?.(event);
  };

  return (
    <FormControl isInvalid={invalid}>
      {label && (
        <FormLabel
          color='primary.pure'
          mb='1'
        >
          {label}
        </FormLabel>
      )}
      <Input
        id={name}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        {...restFiledProps}
        {...style}
      />
      <FormFieldMeta
        errors={{ [name]: error }}
        helperText={helperText}
        name={name}
      />
    </FormControl>
  );
};

export default InputField;
