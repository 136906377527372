import { saveLeadParams } from '@repo/shared/shared';
import { useRouter } from 'next/router';
import { localStorage } from '../utils';

const useSaveLeadParams = (isAds: boolean) => {
  const router = useRouter();

  if (isAds && localStorage.getItem('isShowProducts') !== 'false') {
    localStorage.setItem('isShowProducts', 'false');
  }

  saveLeadParams(router, false);
};

export default useSaveLeadParams;
