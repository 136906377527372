import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { throttle } from '@/shared/helpers';
import { timeInSeconds } from '../constants';
import { localStorage } from '../utils';

const isWindow = typeof window === 'object';
const currentTimeStamp = Math.floor(Date.now() / 1000);

interface GlobalStateProviderProps {
  children: ReactNode;
  isMobileInitValue: boolean;
}

interface GlobalStateData {
  isMobile: boolean;
  quizResult: string | null;
  setQuizResult: (quiz: string) => void;
}

const GlobalStateContext = createContext<GlobalStateData | null>(null);

export const useGlobalState = () => {
  const data = useContext(GlobalStateContext);

  if (data === null) {
    throw new Error('Can not use GlobalStateContext outside globalStateProvider');
  }

  return data;
};

const GlobalStateProvider = ({ children, isMobileInitValue }: GlobalStateProviderProps) => {
  const [isMobile, setIsMobile] = useState(isMobileInitValue);
  const [quizResult, setQuizResult] = useState(localStorage.getItem('quiz'));

  const timeStamp = quizResult && JSON.parse(quizResult).timeStamp;

  if (timeStamp && currentTimeStamp - timeStamp >= timeInSeconds.DAY) {
    localStorage.removeItem('quiz');
    setQuizResult(null);
  }

  useEffect(() => {
    const handleWindowResize = () => {
      throttle(() => setIsMobile(window.innerWidth < 768))();
    };

    if (isWindow) {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
    }
    return () => {
      if (isWindow) {
        window.removeEventListener('resize', handleWindowResize);
      }
    };
  }, []);

  return (
    <GlobalStateContext.Provider value={{ isMobile, quizResult, setQuizResult }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
